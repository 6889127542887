import Main from "./Main";
import VehicleRegistration from "./vehicle-registration";
import { useState } from "react";
import { Button, Container, TextField, Typography, Box } from "@mui/material";

export default function App() {
  const passphrase = "291024";
  const registroPhrase = "chomin me coge por la cola"; // Frase específica para RegistroForm
  const [input, setInput] = useState("");
  const [renderComponent, setRenderComponent] = useState(""); // Puede ser "main" o "registroForm"

  const manejarBusqueda = (e) => {
    e.preventDefault();
    const trimmedInput = input.trim(); // Elimina espacios al inicio y al final
  
    if (trimmedInput === passphrase) {
      setRenderComponent("main"); // Muestra el componente Main
    } else if (trimmedInput === registroPhrase) {
      setRenderComponent("registroForm"); // Muestra el componente RegistroForm
    } else {
      alert("Contraseña incorrecta. Intente de nuevo.");
    }
  };

  return (
    renderComponent === "" ? (
      <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Ingresa la contraseña para continuar
          </Typography>
          <Box component="form" onSubmit={manejarBusqueda} sx={{ mt: 3 }}>
            <TextField
              fullWidth
              label="Ingrese la contraseña"
              variant="outlined"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
            >
              Ingresar
            </Button>
          </Box>
        </Box>
      </Container>
    ) : renderComponent === "main" ? (
      <Main />
    ) : (
      <VehicleRegistration />
    )
  );
}