"use client"

import { useState } from "react"
import { 
  Button, 
  Card, 
  CardContent, 
  Container, 
  TextField, 
  Typography, 
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material"
import { Search, DirectionsCar, CreditCard, AirlineSeatReclineExtra } from "@mui/icons-material"
import axios from "axios"

// Diccionario de colores
const colorDictionary = {
  "BLANCO": "#FFFFFF",
  "GRIS": "#808080",
  "PLATA": "#C0C0C0",
  "AZUL": "#0000FF",
  "GRAFITO": "#4B4B4B",
  "SN": "#FFFFFF",  // Asumido como "sin color específico" o "desconocido"
  "ROJO": "#FF0000",
  "CAFE": "#6F4E37",
  "NEGRO": "#000000",
  "OCRE": "#CC7722",
  "VINO": "#800020",
  "BITONO": "#D2B48C", // Usando un tono 'tan' o 'marrón claro' como aproximado
  "PLATEADO": "#C0C0C0",  // Sinónimo de "PLATA"
  "S N": "#FFFFFF",  // Asumido como "sin color específico" o "desconocido"
  "PLATA ": "#C0C0C0" // Variante de "PLATA"
};

export default function Component() {
  const [busquedaId, setBusquedaId] = useState("")
  const [resultadoBusqueda, setResultadoBusqueda] = useState(null)

  const manejarBusqueda = async (e) => {
    e.preventDefault()
    
    // Realiza la solicitud al backend
    try {

        if (busquedaId <= 6) {
          console.log('incomplete');
          
          throw new Error("busqueda incompleta");
        }
        const response = await axios.get(`https://thenameless.company/api/vehiculos/${busquedaId}`)
        setResultadoBusqueda(response.data);
    } catch (error) {
      console.error(error);
      if (error ==="busqueda incompleta") {
        setResultadoBusqueda("Busqueda incompleta")
      }
      setResultadoBusqueda("no encontrado");
    }
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Búsqueda de Registros de Vehículos
        </Typography>
        <Box component="form" onSubmit={manejarBusqueda} sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Ingrese la matrícula"
            variant="outlined"
            value={busquedaId}
            onChange={(e) => setBusquedaId(e.target.value.toLocaleUpperCase())}
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            startIcon={<Search />}
            size="large"
          >
            Buscar
          </Button>
        </Box>

        {resultadoBusqueda && (
          <Card sx={{ mt: 4 }}>
            <CardContent>
              {resultadoBusqueda === "no encontrado" ? (
                <Typography color="error" align="center">
                  Ingresa el numero de matricula completo.
                </Typography>
              ) : resultadoBusqueda === "Busqueda incompleta" ? (
                <Typography color="error" align="center">
                  No se encontró ningún registro para esta matrícula.
                </Typography>
              )  : (
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CreditCard />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Placas" 
                      secondary={resultadoBusqueda.placas} 
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <AirlineSeatReclineExtra />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Nombre conductor" 
                      secondary={resultadoBusqueda.nombre} 
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DirectionsCar style={{ color: colorDictionary[resultadoBusqueda.color] || resultadoBusqueda.color}} />
                    </ListItemIcon>
                    <ListItemText 
                      primary="Color del Vehículo" 
                      secondary={
                        <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box 
                            component="span" 
                            sx={{ 
                              width: 20, 
                              height: 20, 
                              borderRadius: '50%', 
                              backgroundColor: colorDictionary[resultadoBusqueda.color] || resultadoBusqueda.color,
                              marginRight: 1,
                              border: '1px solid rgba(0, 0, 0, 0.12)'
                            }} 
                          />
                          {resultadoBusqueda.color}
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText 
                      primary="Marca" 
                      secondary={resultadoBusqueda.marca} 
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText 
                      primary="Modelo" 
                      secondary={resultadoBusqueda.modelo} 
                    />
                  </ListItem>
                </List>
              )}
            </CardContent>
          </Card>
        )}
      </Box>
    </Container>
  )
}