"use client";

import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
  Box,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { Add, Edit, Delete, Close } from "@mui/icons-material";
import axios from "axios";

export default function VehicleRegistration() {
  const [vehicles, setVehicles] = useState([]);
  const [formData, setFormData] = useState({
    nombre: "",
    modelo: "",
    color: "",
    año: "",
    placas: "",
    marca: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [marcas, setMarcas] = useState([
    "ABARTH",
    "ACURA",
    "ALFA ROMEO",
    "ARRA",
    "ASTON MARTIN",
    "AUDI",
    "BAIC",
    "BENTLEY",
    "BESTUNE",
    "BMW",
    "BUICK",
    "BYD",
    "CADILLAC",
    "CHANGAN",
    "CHIREY",
    "CHEVROLET",
    "CHRYSLER",
    "CUPRA",
    "DODGE",
    "DFSK",
    "FAW",
    "FERRARI",
    "FIAT",
    "FORD",
    "FOTON",
    "GAC",
    "GEELY",
    "GMC",
    "HAVAL",
    "HONDA",
    "HYUNDAI",
    "INFINITI",
    "JAC",
    "JAECOO",
    "JAGUAR",
    "JEEP",
    "JETOUR",
    "JIM",
    "JMC",
    "KIA",
    "LAMBORGHINI",
    "LAND ROVER",
    "LEXUS",
    "LINCOLN",
    "LOTUS",
    "MASERATI",
    "MASTRETTA",
    "MAZDA",
    "MCLAREN",
    "MERCEDES-BENZ",
    "MG",
    "MINI",
    "MITSUBISHI",
    "NISSAN",
    "OMODA",
    "ORA",
    "PEUGEOT",
    "POER",
    "PORSCHE",
    "RAM",
    "RENAULT",
    "ROLLS-ROYCE",
    "SEAT",
    "SERES",
    "SEV",
    "SKYWELL",
    "SMART",
    "SRT",
    "SUBARU",
    "SUZUKI",
    "TANK",
    "TESLA",
    "TOYOTA",
    "UAZ",
    "VOLKSWAGEN",
    "VOLVO",
    "VUHL",
    "ZACUA",
    "ZEEKR",
  ]);

  const [modelos, setModelos] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const response = await axios.get(
          "https://thenameless.company/api/vehiculos"
        );
        setVehicles(response.data);
        // Crear un conjunto para obtener marcas únicas ignorando mayúsculas/minúsculas
        const uniqueBrands = Array.from(
          new Set([
            ...marcas.map((brand) => brand.toUpperCase()), // Convertimos initialBrands a mayúsculas
            ...response.data.map((vehicle) => vehicle.marca.toUpperCase()), // Convertimos las marcas del servidor a mayúsculas
          ])
        ).map(
          (brand) =>
            brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase()
        ); // Convertir las marcas a "Title Case" para visualización

        // Crear un conjunto para obtener marcas únicas ignorando mayúsculas/minúsculas
        const uniqueModels = Array.from(
          new Set([
            ...response.data.map((vehicle) => vehicle.modelo.toUpperCase()), // Convertimos los modelos del servidor a mayúsculas
          ])
        ).map(
          (model) =>
            model.charAt(0).toUpperCase() + model.slice(1).toLowerCase()
        ); // Convertir las marcas a "Title Case" para visualización

        const uniqueColors = Array.from(
          new Set([
            ...response.data.map((vehicle) => vehicle.color.toUpperCase()), // Convertimos los modelos del servidor a mayúsculas
          ])
        )
        setModelos(uniqueModels);
        setMarcas(uniqueBrands); // Almacenar en el estado (declarado previamente)
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    };
    fetchVehicles();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        const response = await axios.put(
          `https://thenameless.company/api/vehiculos/${vehicles[editIndex]._id}`,
          formData
        );
        const updatedVehicles = [...vehicles];
        updatedVehicles[editIndex] = response.data;
        setVehicles(updatedVehicles);
      } else {
        const response = await axios.post(
          "https://thenameless.company/api/vehiculos",
          formData
        );
        setVehicles([...vehicles, response.data]);
      }
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleEdit = (index) => {
    setFormData(vehicles[index]);
    setIsEditing(true);
    setEditIndex(index);
    setOpenDialog(isMobile);
  };

  const handleDelete = async (index) => {
    const vehicleId = vehicles[index]._id;
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar este vehículo?"
    );

    if (confirmDelete) {
      try {
        await axios.delete(
          `https://thenameless.company/api/vehiculos/${vehicleId}`
        );
        setVehicles(vehicles.filter((_, i) => i !== index));
      } catch (error) {
        console.error("Error deleting vehicle:", error);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      nombre: "",
      modelo: "",
      color: "",
      año: "",
      placas: "",
      marca: "",
    });
    setIsEditing(false);
    setEditIndex(null);
    setOpenDialog(false);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        align="center"
        sx={{ my: 4 }}
      >
        Registro de Vehículos
      </Typography>

      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : "300px",
            flexShrink: 0,
            mr: isMobile ? 0 : 4,
            mb: isMobile ? 4 : 0,
            position: isMobile ? "static" : "sticky",
            top: isMobile ? "auto" : "20px",
            alignSelf: "flex-start",
          }}
        >
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {isEditing ? "Editar Vehículo" : "Agregar Vehículo"}
              </Typography>
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Nombre del Conductor"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <Autocomplete
                  freeSolo
                  options={marcas}
                  value={formData.marca}
                  onChange={(e, newValue) =>
                    setFormData({ ...formData, marca: newValue || "" })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marca"
                      name="marca"
                      onChange={handleChange}
                      margin="normal"
                      required
                    />
                  )}
                />
                <Autocomplete
                  freeSolo
                  options={modelos}
                  value={formData.modelo}
                  onChange={(e, newValue) =>
                    setFormData({ ...formData, modelo: newValue || "" })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Modelo"
                      name="modelo"
                      onChange={handleChange}
                      margin="normal"
                      required
                    />
                  )}
                />
                <TextField
                  fullWidth
                  label="Color"
                  name="color"
                  value={formData.color}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Año"
                  name="año"
                  type="number"
                  value={formData.año}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Placas"
                  name="placas"
                  value={formData.placas}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  startIcon={isEditing ? <Edit /> : <Add />}
                >
                  {isEditing ? "Actualizar" : "Agregar"}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>

        <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">
              Lista de Vehículos
            </Typography>
            <Typography variant="subtitle1">
              Total de registros: {vehicles.length}
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {vehicles.map((vehicle, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={vehicle._id}>
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      {vehicle.nombre}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {vehicle.marca} {vehicle.modelo}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {vehicle.color}, {vehicle.año}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Placas: {vehicle.placas}
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => handleEdit(index)}
                        aria-label="edit"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => handleDelete(index)}
                        aria-label="delete"
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={resetForm} fullScreen={isMobile}>
        <DialogTitle>
          {isEditing ? "Editar Vehículo" : "Agregar Vehículo"}
          <IconButton
            aria-label="close"
            onClick={resetForm}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Nombre del Conductor"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Marca"
              name="marca"
              value={formData.marca}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Modelo"
              name="modelo"
              value={formData.modelo}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Color"
              name="color"
              value={formData.color}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Año"
              name="año"
              type="number"
              value={formData.año}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Placas"
              name="placas"
              value={formData.placas}
              onChange={handleChange}
              margin="normal"
              required
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetForm}>Cancelar</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {isEditing ? "Actualizar" : "Agregar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
